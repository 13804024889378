import React from 'react';
import { graphql } from 'gatsby';
import { buildImageObj, imageUrlFor } from '../components/sanity/image-url';

import SEO from '../components/seo';
import Wrapper from '../containers/next/wrapper';
import Header from '../components/next/header/header';
import FixedHero from '../components/next/fixed-hero/fixed-hero';
import BottomFoldWrapper from '../components/next/bottom-fold-wrapper/bottom-fold-wrapper';
import Benefits from '../components/next/benefits/benefits';
import ResponsiveCarousel from '../components/responsive-carousel/responsive-carousel';
import HowToJoinCallout from '../components/next/how-to-join-callout/how-to-join-callout';
import PortfolioCompanies from '../components/next/portfolio-companies/portfolio-companies';
import FinalHero from '../components/next/final-hero/final-hero';
import Graphic from '../images/next/bg.jpg';

export const query = graphql`
query Home {
  site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    title
    description
    keywords
  }
  nextHome: sanityNextHome {
    id
    title
    ctaText
    ctaLink
    heroTitle
    heroSubtitle
    heroBullet1Title
    heroBullet1Subtext
    heroBullet2Title
    heroBullet2Subtext
    heroBullet3Title
    heroBullet3Subtext
    heroBullet4Title
    heroBullet4Subtext
    carouselImages {
        asset {
            _id
        }
    }
    floatingCalloutTitle
    floatingCalloutSubtitle
    floatingCalloutListTitle
    floatingCalloutListItems
    featuredCompanies {
      altText
      image {
        asset {
          _id
        }
      }
      url
    }
  }
}
`;

const NextHomeTemplate = props => {
    const { data } = props;

    const siteTitle = data?.site?.title || 'Next Homepage';
    const siteDescription = data?.site?.description || '';
    const siteKeywords = data?.site?.keywords || [];
  
    const carouselImages = data?.nextHome?.carouselImages || [];
    const featuredCompanies = data?.nextHome?.featuredCompanies || [];
    const ctaText = data?.nextHome?.ctaText || '';
    const ctaLink = data?.nextHome?.ctaLink || '/';

    const heroTitle = data?.nextHome?.heroTitle || 'How you benefit';
    const heroSubtitle = data?.nextHome?.heroSubtitle || '';
    const heroBullet1Title = data?.nextHome?.heroBullet1Title || '';
    const heroBullet1Subtext = data?.nextHome?.heroBullet1Subtext || '';
    const heroBullet2Title = data?.nextHome?.heroBullet2Title || '';
    const heroBullet2Subtext = data?.nextHome?.heroBullet2Subtext || '';
    const heroBullet3Title = data?.nextHome?.heroBullet3Title || '';
    const heroBullet3Subtext = data?.nextHome?.heroBullet3Subtext || '';
    const heroBullet4Title = data?.nextHome?.heroBullet4Title || '';
    const heroBullet4Subtext = data?.nextHome?.heroBullet4Subtext || '';
    const heroBulletList = [{title: heroBullet1Title, subText: heroBullet1Subtext}, {title: heroBullet2Title, subText: heroBullet2Subtext}, {title: heroBullet3Title, subText: heroBullet3Subtext}, {title: heroBullet4Title, subText: heroBullet4Subtext}];

    const floatingCalloutTitle = data?.nextHome?.floatingCalloutTitle || 'How you join';
    const floatingCalloutSubtitle = data?.nextHome?.floatingCalloutSubtitle || '';
    const floatingCalloutListTitle = data?.nextHome?.floatingCalloutListTitle || 'What we’re looking for:';
    const floatingCalloutListItems = data?.nextHome?.floatingCalloutListItems || [];
      
    return (
        <Wrapper>
            <SEO title={siteTitle} description={siteDescription} keywords={siteKeywords} bodyClasses="page-home page-paddingtop-0 page-next" bodyId="" />
  
            {typeof window !== 'undefined' && (
                <>
                    <Header ctaText={ctaText} ctaLink={ctaLink} />
                    <FixedHero />
                    <BottomFoldWrapper>
                    <img className='graphic' src={Graphic} />
                        <Benefits heroTitle={heroTitle} heroSubtitle={heroSubtitle} heroBulletList={heroBulletList} />
                        <ResponsiveCarousel animationHandler="fade" swipeable={false} autoPlay={true} showStatus={false} showThumbs={false} infiniteLoop={true} showArrows={false} transitionTime={1000} interval={4000}>
                            {carouselImages.map((image, index) => (
                                <div key={`carousel-item-${index}`}>
                                    <img src={imageUrlFor(buildImageObj(image))} />
                                </div>
                            ))}
                        </ResponsiveCarousel>
                        <HowToJoinCallout title={floatingCalloutTitle} subtitle={floatingCalloutSubtitle} ctaText={ctaText} ctaLink={ctaLink} listTitle={floatingCalloutListTitle} listItems={floatingCalloutListItems} />
                        <PortfolioCompanies companies={featuredCompanies} />
                        <FinalHero ctaText={ctaText} ctaLink={ctaLink} />
                    </BottomFoldWrapper>
                </>
            )}
        </Wrapper>
    );
};

export default NextHomeTemplate;
